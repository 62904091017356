import React, { useContext, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import GlobalContext from "../contexts/GlobalContext"
import { allLangs } from "../utils/configClient"

import PageWrapper from "../components/PageWrapper"
import Hero from "../sections/common/Hero"
import Support from "../sections/common/Support"
import Contact from "../sections/common/Contact"
import { Section, Title, Box } from "../components/Core"
import { slugHonor, slugHonors, slugHome } from "../utils/slugs"

import ProductAccordion from "../components/ProductAccordion"
import ProductSlider from "../components/ProductSlider"
import bgImg from "../assets/images/bg3.jpg"

const HonorItem = ({ data, uid, index }) => {
  const gContext = useContext(GlobalContext)

  return (
    <Box
      className={`d-flex align-items-center  py-4 w-100 border-bottom ${
        index == 0 ? `border-top` : ``
      }`}
    >
      {data.certificates.length > 0 && data.certificates[0].image && (
        <Link to={slugHonor(uid, gContext.currentLang)} className="d-flex">
          <Img
            className="cover-image"
            fluid={data.certificates[0].image.fluid}
            alt={""}
            css={`
              width: 200px;
            `}
          />
        </Link>
      )}

      <Link
        to={slugHonor(uid, gContext.currentLang)}
        className="d-flex  ml-3 ml-md-4"
      >
        <Title variant="card">{data.title.text}</Title>
      </Link>
    </Box>
  )
}

const Honors = ({ data }) => {
  const gContext = useContext(GlobalContext)

  useEffect(() => {
    if (gContext.currentLang !== allLangs.hk) {
      gContext.setCurrentLang(allLangs.hk)
    }
  }, [])

  const { btn_home } = data.prismicCommon?.data
  const homeData = data.prismicHomePage?.data
  const honorNodes = data.allPrismicHonor.nodes

  return (
    <>
      <PageWrapper>
        <Hero bgImg={homeData?.honor_bg.url || bgImg}>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                {homeData?.honor_title && (
                  <Title variant="hero" color="light">
                    {homeData?.honor_title.text}
                  </Title>
                )}
                <Box mt={4}>
                  <div className="d-flex my-3 justify-content-center">
                    <div className="text-white font-weight-bold">
                      {">>"}
                      <Link
                        to={slugHome(gContext.currentLang)}
                        className="text-white font-weight-bold"
                      >
                        {btn_home ? btn_home.text : "Home"}
                      </Link>
                    </div>
                  </div>
                </Box>
              </Col>
            </Row>
          </Container>
        </Hero>
        <Section
          pt={["45px !important", null, null, "60px !important"]}
          className="pb-5"
        >
          <Container fluid>
            <Row className="justify-content-center">
              <Col lg="9" className="pb-5 pb-lg-0">
                <Box mb="40px">
                  <div className="d-flex my-3 justify-content-left">
                    <div className="font-weight-bold">
                      {">>"}
                      <Link
                        to={slugHome(gContext.currentLang)}
                        className="font-weight-bold breadcrumb-link "
                      >
                        {btn_home ? btn_home.text : "Home"}
                      </Link>
                    </div>
                    <div className="font-weight-bold ml-2">
                      {">>"}
                      <Link
                        to={slugHonors(gContext.currentLang)}
                        className="font-weight-bold breadcrumb-link "
                      >
                        {homeData?.honor_title
                          ? homeData?.honor_title.text
                          : "Company Honor"}
                      </Link>
                    </div>
                  </div>
                </Box>
                <Box>
                  {honorNodes.map(({ data, uid }, index) => {
                    return (
                      <HonorItem
                        key={index}
                        index={index}
                        data={data}
                        uid={uid}
                      />
                    )
                  })}
                </Box>
              </Col>
              <Col lg="3">
                <ProductAccordion />
                <ProductSlider />
              </Col>
            </Row>
          </Container>
        </Section>
        <div id="support">
          <Support />
        </div>
        <div id="contact">
          <Contact />
        </div>
      </PageWrapper>
    </>
  )
}
export default Honors

export const query = graphql`
  query($lang: String!) {
    prismicCommon(lang: { eq: $lang }) {
      data {
        btn_home {
          text
        }
      }
    }

    prismicHomePage(lang: { eq: $lang }) {
      data {
        honor_title {
          text
        }
        honor_bg {
          url
        }
      }
    }

    allPrismicHonor(
      sort: { order: DESC, fields: data___year }
      filter: { lang: { eq: $lang } }
    ) {
      nodes {
        uid
        data {
          title {
            text
          }
          year
          certificates {
            image {
              fluid {
                ...GatsbyPrismicImageFluid
              }
            }
          }
        }
      }
    }
  }
`
